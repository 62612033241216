@import "../../../styles/theme.scss";

.howItWorks {
  &__container {
    @media (max-width: $medium) {
      padding: 0px 50px;
      width: 100%;
    }
  }

  &__titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 48px;
  }

  &__title {
    text-align: center;
  }

  &__body {
    width: 50%;
    text-align: center;
    margin-bottom: 0;

    @media (max-width: $small) {
      width: 100%;
    }
  }

  &__bullet {
    padding: 0;
  }

  &__buttonContainer {
    margin-top: 50px;
    text-align: center;
  }

  &__bulletMargin {
    @media (max-width: $small) {
      margin-top: 45px;
    }
  }
}

.howItWorksBullet {
  &__image {
    max-width: 270px;
    max-height: 177px;

    @media (max-width: $medium) {
      max-width: 150px;
    }

    @media (max-width: $small) {
      max-width: 100%;
      max-height: 400px;
    }
  }

  &__imageWrapper {
    min-height: 177px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (max-width: $small) {
      width: 100%;
      max-height: auto;
      min-height: 150px;
    }
  }

  &__text {
    margin-top: 25px;
    text-align: center;
    color: $secondary;
    font-size: 22px;
    font-weight: 500;

    @media (max-width: $small) {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
}
