@import "../../../styles/theme.scss";

.HomeFAQ {
  margin-top: 100px;
  margin-bottom: 130px;
  text-align: center;

  &__title {
    padding: 0 20px;
  }

  &__container {
    @media (max-width: $medium) {
      padding: 0;
    }
  }
}
