@import "../../../styles/theme.scss";

.logoSlider {
  position: absolute;
  top: calc(50% - 64px);
  right: 48px;
  height: 128px;
  width: 128px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 15px 30px 0 rgba(9, 28, 55, 0.1);

  @media (max-width: $medium) {
    top: 20px;
    right: 20px;
    height: 64px;
    width: 64px;
  }
}

.sliderContainer {
  height: 100%;
  width: 100%;
}

.logoContainer {
  width: 128px !important;
  height: 128px;
  display: flex !important;
  justify-content: center;
  align-items: center;

  @media (max-width: $medium) {
    width: 64px !important;
    height: 64px;
  }
}

.logo {
  // max-width: 100%;
  max-height: 70%;
  max-width: 70%;
}
