@import "../../styles/theme.scss";

.table {
  display: none;
  border-collapse: collapse;
  border-spacing: 0;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  margin-bottom: 20px;

  margin: auto;

  &__responsive {
    min-width: 500px;
  }

  &__two__col {
    width: 100%;

    .dataCell,
    .headerCell {
      &:last-child {
        text-align: right;
        font-weight: 300;
      }
    }
  }

  @media screen and (min-width: $medium) {
    display: block;
  }
}

.tableWrapper {
  &--responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}

.dataCell,
.headerCell {
  text-align: left;
  padding: 7px 10px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  border-bottom: 1px solid $pale-mint;
}

.dataCell {
  // color: @table-text-color;
  font-size: 14px;
  font-weight: normal;
}

.dataRow {
  &:last-child .dataCell {
    border-bottom: none;
  }
}
