@import "../../../styles/theme.scss";

.outerContainer {
  width: 1440px;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  margin-bottom: 54px;

  @media (max-width: $small) {
    margin-bottom: 32px;
  }
}

.innerContainer {
  display: grid;
  gap: 24px;
  padding: 40px 24px;
  grid-template-columns: calc(23% - 18px) calc(26% - 18px) calc(30% - 18px) calc(
      20% - 18px
    );
  grid-template-rows: auto;
  align-items: center;
  max-width: 100%;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 40%);
    grid-template-rows: auto;
    overflow: scroll;
  }
  @media (max-width: $xsmall) {
    grid-template-columns: repeat(4, 85%);
    grid-template-rows: auto;
  }

  // @media (max-width: $xsmall) {
  //   grid-template-columns: 100%;
  //   grid-template-rows: auto auto auto auto;
  // }
}
