@import "../../../styles/theme.scss";

.container {
  margin-bottom: 64px;
  padding: 40px 60px 40px 48px;
  background-color: $navy;
  position: relative;

  @media (max-width: $small) {
    padding: 20px;
  }
}

.containerClickable {
  cursor: pointer;
}

.largeMargin {
  margin-bottom: 120px;

  @media (max-width: $small) {
    margin-bottom: 64px;
  }
}

.containerBlur {
  position: absolute;
  top: 0;
  left: 16px;
  right: 0;
  height: 220.2px;
  z-index: 1;

  margin: 0 0 35px 16px;
  transform: rotate(-180deg);
  opacity: 0.2;
  -webkit-filter: blur(200px);
  filter: blur(200px);
  background-color: #2490f3;
}

.contentContainer {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 52px;

  @media (max-width: $small) {
    flex-direction: column;
    gap: 32px;
  }

  &__large {
    @media (max-width: $large) {
      flex-direction: column;
      gap: 32px;
    }
  }
}

.claimedAwardImage {
  @media (max-width: $small) {
    height: 132px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  color: #fff;
  margin-bottom: 8px;

  p {
    margin-bottom: 8px;
    font-size: 28px;
    font-weight: 500;
    color: white;
    line-height: 175%;
  }
}

.tiickerPerkBadgeImage {
  height: 23px;
}

.description {
  color: #fff;
  margin-bottom: 8px;
  line-height: 1.5;

  p {
    color: #fff;
    margin-bottom: 8px;
    line-height: 1.5;
  }
  span {
    color: white;
    margin-bottom: 0;
  }
}

.connectButton {
  color: white;

  &:hover {
    color: white;
  }
}
