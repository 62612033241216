@import "../../../styles/theme.scss";

.tableWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tableTitle {
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.tableCitation {
  text-align: center;
  font-size: 12px;
  color: $light-grey;
}
