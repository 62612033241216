@import "../../styles/theme.scss";

.ChooseYourDevice {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // padding: 64px 34px;
  }

  &__image {
    object-fit: contain;
    width: 100%;
    height: auto;
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 34px;
  }

  &__header {
    text-align: center;
  }

  &__subHeader {
    margin: 0;
    text-align: center;
  }

  &__CTAButton {
    margin-top: 32px;
  }

  @media screen and (min-width: $medium) {
    &__content {
      flex-direction: row;
      align-items: center;
      padding: 64px 0;
    }

    &__image {
      max-width: 584px;
    }

    &__headerContainer {
      align-items: flex-start;
      max-width: 350px;
      margin-top: 0;
      margin-left: 120px;
    }

    &__header,
    &__subHeader {
      text-align: left;
      max-width: max-content;
    }
  }

  @media screen and (min-width: $large) {
    &__image {
      max-width: 662px;
    }
  }
}
