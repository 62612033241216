@import "../../styles/theme.scss";

.Insights {
  width: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // padding: 64px 34px;
    margin-top: 120px;

    @media (max-width: $small) {
      margin-top: 72px;
    }
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;
  }

  p {
    font-size: 18px;
    text-align: center;
    color: #575a60;
    margin: 0;
  }

  &__CTAButtonMobile,
  &__CTAButtonDesktop {
    margin-top: 34px;
    margin-bottom: 64px;
  }

  &__CTAButtonDesktop {
    display: none;
  }

  &__canvas {
    width: 100%;
    max-width: 383px;
  }

  @media screen and (min-width: $medium) {
    &__content {
      padding: 120px 0;
    }

    h2,
    p {
      text-align: left;
      max-width: max-content;
    }

    &__canvas {
      margin-left: 32px;
      width: fit-content;
      max-width: 100%;
    }
  }

  @media screen and (min-width: $large) {
    &__content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__headerContainer {
      align-items: flex-start;
      max-width: 370px;
    }

    &__CTAButtonMobile {
      display: none;
    }

    &__CTAButtonDesktop {
      display: flex;
    }
  }
}
