@import "../../../styles/theme.scss";

.BrandSearch {
  margin-top: 80px;
  margin-bottom: 32px;
  padding-top: 180px;

  position: relative;

  @media (max-width: $small) {
    padding-top: 150px;

    margin-top: 72px;
    margin-bottom: 24px;
  }

  @media (max-width: $small) {
    &:before,
    &:after {
      display: none;
    }
  }

  &__titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    text-align: center;
  }

  &__body {
    width: 50%;
    text-align: center;

    @media (max-width: $small) {
      width: 90%;
    }
  }

  &__searchContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__input {
    width: 100%;
    height: 94px;
  }

  &__leftLogos {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    max-width: 100%;
    height: 300px;
    align-items: flex-start;

    @media (max-width: $small) {
      width: 100%;
      padding: 0 100px;
    }
  }

  &__rightLogos {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 200px;
    max-width: 100%;
    height: 300px;
    align-items: flex-start;
    right: 0px;

    @media (max-width: $small) {
      width: 100%;
      padding: 0 100px;
    }
  }

  $topRowBase: 10px;
  $bottomRowBase: 90px;
  $bottomSearchBar: 342px;
  $logoWidth: 150px;
  $halfLogoWidth: calc($logoWidth / 2);

  &__logoContainer {
    position: absolute;
    width: $logoWidth;
    height: 40px;

    // here goes bad things
    &:nth-child(1) {
      left: calc(50% - $halfLogoWidth);
      top: $bottomRowBase;
    }

    &:nth-child(2) {
      left: calc(30% - $halfLogoWidth);
      top: calc($bottomRowBase - 20px);
    }

    &:nth-child(3) {
      left: calc(75% - $halfLogoWidth);
      top: calc($bottomRowBase - 15px);
    }

    &:nth-child(4) {
      left: calc(10% - $halfLogoWidth);
      top: calc($bottomRowBase + 40px);
    }
    &:nth-child(5) {
      left: calc(98% - $logoWidth);
      top: calc($bottomRowBase + 60px);
    }

    &:nth-child(6) {
      left: calc(0% - $halfLogoWidth);
      top: calc($bottomRowBase + 160px);
    }
    &:nth-child(7) {
      left: calc(103% - $logoWidth);
      top: calc($bottomRowBase + 180px);
    }

    &:nth-child(8) {
      left: calc(60% - $halfLogoWidth);
      top: calc($topRowBase + 10px);
    }
    &:nth-child(9) {
      left: calc(37% - $halfLogoWidth);
      top: calc($topRowBase - 20px);
    }
    &:nth-child(10) {
      left: calc(93% - $halfLogoWidth);
      top: $topRowBase;
    }
    &:nth-child(11) {
      left: calc(16% - $halfLogoWidth);
      top: calc($topRowBase - 20px);
    }
    &:nth-child(12) {
      left: calc(0% - $halfLogoWidth);
      top: calc($topRowBase + 40px);
    }

    @media (max-width: $medium) {
      &:nth-child(3) {
        left: calc(80% - $halfLogoWidth);
        top: calc($bottomRowBase + 5px);
      }

      &:nth-child(4) {
        top: calc($bottomRowBase + 20px);
      }

      &:nth-child(8) {
        left: calc(64% - $halfLogoWidth);
      }
    }

    @media (max-width: $small) {
      // &:nth-child(8),
      // &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        display: none;
      }

      &:nth-child(1) {
        left: calc(50% - $halfLogoWidth);
        top: calc($topRowBase - 10px);
      }

      &:nth-child(2) {
        left: calc(25% - $halfLogoWidth);
        top: calc($bottomRowBase - 10px);
      }

      &:nth-child(3) {
        left: calc(85% - $halfLogoWidth);
        top: calc($bottomRowBase - 25px);
      }

      &:nth-child(4) {
        left: calc(55% - $halfLogoWidth);
        top: calc($bottomRowBase + 0px);
      }

      &:nth-child(5) {
        left: calc(50% - $halfLogoWidth);
        top: calc($bottomSearchBar + $topRowBase - 10px);
      }

      &:nth-child(6) {
        left: calc(25% - $halfLogoWidth);
        top: calc($bottomSearchBar + $bottomRowBase - 10px);
      }

      &:nth-child(7) {
        left: calc(85% - $halfLogoWidth);
        top: calc($bottomSearchBar + $bottomRowBase - 25px);
      }

      &:nth-child(8) {
        left: calc(55% - $halfLogoWidth);
        top: calc($bottomSearchBar + $bottomRowBase + 0px);
      }

      &:nth-child(9) {
        left: calc(10% - $halfLogoWidth);
        top: calc($topRowBase - 30px);
      }
    }

    @media (max-width: $xsmall) {
      &:nth-child(9) {
        display: none;
      }

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(3) {
        top: $topRowBase;
        left: calc(100% - $logoWidth);
      }

      &:nth-child(4) {
        top: $bottomRowBase;
        left: calc(100% - $logoWidth);
      }

      &:nth-child(5) {
        top: $bottomSearchBar;
        left: 0;
      }

      &:nth-child(7) {
        top: $bottomSearchBar + $topRowBase;
        left: calc(100% - $logoWidth);
      }

      &:nth-child(8) {
        top: $bottomSearchBar + $bottomRowBase;
        left: calc(100% - $logoWidth);
      }
    }
  }

  &__mediumLogo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: grayscale(1);
    opacity: 0.2;
    transition: transform 0.3s, opacity 0.3s, left 0.3s, top 0.3s;
    transform: scale(1);
    cursor: pointer;
    left: 0;
    top: 0;

    &:hover {
      transform: scale(1.2);
      opacity: 0.4;
    }
  }
}

.input::placeholder {
  color: #797b80 !important;
}

.searchContainer {
  margin-bottom: 118px;

  @media (max-width: $small) {
    margin-bottom: 180px;
  }
}
