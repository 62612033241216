@import "../../../styles/theme.scss";

.InsightsMosaic {
  &__cardsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    width: 100%;
  }

  @media screen and (min-width: $medium) {
    &__cardsContainer {
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      max-width: 100%;
      grid-template-rows: min-content repeat(2, auto);
    }
  }
}

.row {
  grid-column-start: 1;
  grid-column-end: 3;

  @media screen and (max-width: $medium) {
    grid-column: auto;
    grid-row: auto;
  }
}

.column {
  grid-row-start: 2;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (max-width: $medium) {
    grid-column: auto;
    grid-row: auto;
  }
}

.block {
  grid-column: auto;
  grid-column-start: 2;
  grid-column-end: 3;

  @media screen and (max-width: $medium) {
    grid-column: auto;
    grid-row: auto;
  }
}
