@import "styles/theme.scss";

.HomeFeaturedPerks {
  &__container {
    padding: 72px 0 100px;
    background-color: $pale-mint;
    margin-top: 110px;

    @media (max-width: $small) {
      margin-top: 72px;
      padding: 40px 0;
    }
  }
}

.slide {
  width: auto !important;
  margin-right: 24px;
}

.title {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 32px;

  @media (max-width: $small) {
    font-size: 14px;
  }
}
