@import "../../../styles/theme.scss";

.FeaturedPerkTile {
  display: flex;
  // margin-right: 40px;
  flex-direction: column;
  max-width: min-content;
  cursor: pointer;
  position: relative;
  margin-bottom: 80px;
  margin-right: 24px;

  @media (max-width: $small) {
    margin-right: 0;
    margin-bottom: 85px;
  }

  &__imageContainer {
    position: relative;
    width: fit-content;
  }

  &__featuredPill {
    position: absolute;
    right: 32px;
    top: 32px;
    background-color: $green-highlight;
    color: $navy;

    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 1.54px;
    padding: 8px 18px 10px;

    border-radius: 50px;

    @media (max-width: $medium) {
      right: 24px;
      top: 24px;
    }

    @media (max-width: $small) {
      right: 16px;
      top: 16px;
      font-size: 8px;
      letter-spacing: 1.12px;
      padding: 6px 15px 8px;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    width: auto;
    object-fit: cover;

    height: 500px;

    @media (max-width: $medium) {
      height: 350px;
    }

    @media (max-width: $small) {
      height: 200px;
    }

    @media (max-width: $xsmall) {
      max-width: calc(100vw - (100vw * 0.25));
    }
  }

  &__textContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    left: 0;

    @media (max-width: $medium) {
      top: calc(100% + 7px);
      // display: unset;
    }
  }

  &__title {
    color: $navy;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.28px;
    margin-right: 10px;

    @media (max-width: $medium) {
      width: 100%;
      white-space: normal;
      font-size: 20px;
      line-height: 1.25;
      letter-spacing: -0.2px;
    }
  }

  &__logo {
    // height: 35px;
    max-height: 55px;
    max-width: 140px;
    @media (max-width: $small) {
      display: none !important;
    }
  }
}
