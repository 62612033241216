@import "../../../styles/theme.scss";

$animation-multiplier: 1;
$text-duration: 0.5s * $animation-multiplier;
$button-duration: 0.5s * $animation-multiplier;

$text-delay: $text-duration * 0.5;
$button-delay: $text-delay + ($text-duration * 0.5);

@keyframes titleSlideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes buttonSlideIn {
  from {
    transform: translateY(80px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.wrapper {
  width: 100%;
  margin: 40px auto 64px;
  padding: 0 32px;
  text-align: center;

  @media (max-width: $small) {
    margin-bottom: 72px;
  }
}

.title {
  text-align: center;
  margin-bottom: 0;

  transform: translateY(100%);
  animation-name: titleSlideIn;
  animation-duration: $text-duration;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;

  &_Delayed {
    animation-delay: $text-delay;
  }
}

.titleAnimation {
  overflow: hidden;

  .title {
  }
}

.titleWrapper {
  margin-bottom: 50px;

  @media (max-width: $small) {
    margin-bottom: 40px;
  }
}

.button {
  transform: translateY(80px);
  opacity: 0;

  animation-name: buttonSlideIn;
  animation-duration: $button-duration;
  animation-fill-mode: forwards;
  animation-delay: $button-delay;
  animation-timing-function: ease-out;
}
