@import "../../styles/theme.scss";

.NftFaq {
  margin-bottom: 40px;
  padding: 0 20px;

  &__panel {
    position: relative;
    margin-bottom: -2px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      opacity: 0.3;
      transition: opacity 0.3s;
      border-style: solid;
      border-width: 2px;
      border-image-source: linear-gradient(
        98deg,
        #a17637 6%,
        #c8ae6e 53%,
        #a17637 100%
      );

      border-image-slice: 1;
    }

    &--open {
      &:before {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  &__loadingTitle {
    height: 34px;
    width: 500px;
    max-width: 80%;
    background-color: #2c2722;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      left: -100px;
      top: -50%;
      width: 100px;
      height: 200%;
      transform: rotate(20deg);
      background: rgb(15, 13, 16);
      background: linear-gradient(
        90deg,
        rgba(15, 13, 16, 0) 0%,
        rgba(15, 13, 16, 0.5) 50%,
        rgba(15, 13, 16, 0) 100%
      );
      animation: loading 1.2s infinite;
      animation-timing-function: ease-in-out;
    }
  }

  @keyframes loading {
    to {
      left: calc(100% + 100px);
    }
  }

  &__title {
    color: white;
    font-family: "Bebas Neue" !important;
    font-size: 28px;
    letter-spacing: 1.4px;
    line-height: 0.95;
    cursor: pointer;
    padding: 25px 26px 23px 32px;
    position: relative;
    z-index: 3;
  }

  &__innerTitle {
    padding-right: 30px;
  }

  &__toggle {
    opacity: 0.7;
    transition: opacity 0.3s;

    &:before,
    &:after {
      position: absolute;
      right: 30px;
      top: 27px;
      content: "";
      display: block;
      width: 1.5px;
      height: 20px;
      background-color: #caac7d;
      transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &:after {
      transform: rotate(-90deg);
    }

    &--open {
      opacity: 1;
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &__content {
    color: white;
    opacity: 0.7;
  }

  &__innerContent {
    padding: 0 26px 23px 32px;

    p {
      font-size: 18px;
      line-height: 1.56;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.HomeFaq {
  margin: 50px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__panel {
    position: relative;
    margin-bottom: 20px;
    max-width: 850px;
    width: 100%;

    border: solid 1px #85bb65;

    @media (max-width: $small) {
      margin-bottom: -2px;
      border: none;
      border-top: solid 1px #85bb65;
    }

    &:last-child {
      border-bottom: solid 1px #85bb65;
    }

    &__open {
      &:before {
        opacity: 1;
        z-index: 4;
      }
      border: solid 1px transparent !important;
      box-shadow: 0 0 30px 0 rgba(9, 28, 55, 0.13);

      transition: all 0.7s ease;

      @media (max-width: $small) {
        border: none;
        border-top: solid 1px transparent;
        & + .HomeFaq__panel {
          border-top: none;
        }
      }
    }
  }

  &__loadingTitle {
    height: 34px;
    width: 500px;
    max-width: 80%;
    background-color: #f0f7f5;
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      left: -100px;
      top: -50%;
      width: 100px;
      height: 200%;
      transform: rotate(20deg);
      background: rgb(15, 13, 16);
      background: linear-gradient(
        90deg,
        rgba(15, 13, 16, 0) 0%,
        rgba(15, 13, 16, 0.5) 50%,
        rgba(15, 13, 16, 0) 100%
      );
      animation: loading 1.2s infinite;
      animation-timing-function: ease-in-out;
    }
  }

  @keyframes loading {
    to {
      left: calc(100% + 100px);
    }
  }

  &__title {
    text-align: left;
    font-family: neue-haas-unica, sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.1;
    color: #091c37;
    cursor: pointer;
    padding: 25px 50px 23px 32px;
    position: relative;
    z-index: 3;

    @media (max-width: $small) {
      font-size: 20px;
    }
  }

  &__innerTitle {
    padding-right: 40px;
  }

  &__toggle {
    opacity: 1;

    &:before,
    &:after {
      position: absolute;
      right: 30px;
      top: 27px;
      content: "";
      display: block;
      width: 1.5px;
      height: 20px;
      background-color: #85bb65;
      transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &:after {
      transform: rotate(-90deg);
    }

    &__open {
      opacity: 1;
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &__content {
    text-align: left;
    color: #575a60;
    size: 18px;
  }

  &__innerContent {
    padding: 0 26px 23px 32px;

    p {
      font-size: 18px;
      line-height: 1.56;
      font-family: neue-haas-unica, san-serif;
      line-height: 1.75;
      color: #575a60;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
