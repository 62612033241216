@import "../../../styles/theme.scss";

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $pale-mint;
  box-shadow: 0px 0px 0px rgba(9, 28, 55, 0.06);
  cursor: pointer;

  transition: all ease-out 0.3s;

  &:hover {
    box-shadow: 0px 15px 30px rgba(9, 28, 55, 0.06);
    transform: translate(0, -8px);
  }

  &__details {
    display: flex;
    align-items: center;

    width: 100%;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__date {
    font-size: 14px;
    margin: 0;
    font-weight: 300;
    color: $grey;
  }

  .img {
    width: 100%;
    max-height: 261px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &__cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 32px 28px 18px;
    // background-color: $pale-mint;
    height: 50%;

    h3 {
      font-size: 24px;
      line-height: 1.2;
      text-align: left;
      color: #091c37;
      max-width: 290px;
      margin: 0;
    }
  }

  &__read {
    margin: 0;
    margin-top: auto;
    font-size: 16px;
    text-align: left;
    color: #091c37;
    cursor: pointer;
    font-family: neue-haas-unica, sans-serif;
  }

  &__badge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    border-radius: 100px;
    background-color: #fff;
    width: fit-content;

    font-size: 11px;
    line-height: 1.1;
    letter-spacing: 2.09px;
    text-align: center;
    color: #091c37;
    text-transform: uppercase;
  }

  &__cardLayoutBlock {
    .img {
      display: none;
    }
  }

  @media screen and (min-width: $medium) {
    &__cardLayoutRow {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 50%;
        min-width: calc(50% - 13px);
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }

    &__cardLayoutRowReversed {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 50%;
        min-width: calc(50% - 13px);
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }

    &__cardLayoutColumn {
      flex-direction: column;
      height: 100%;
    }

    &__cardLayoutHalfColumn {
      height: 100%;
      align-items: flex-start;
      .img {
        min-height: 50%;
      }

      &__cardContent {
        width: 80%;
      }
    }

    &__cardLayoutBlock {
      grid-column: auto;
    }

    &__read {
      font-size: 16px;
      text-align: left;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.description {
  margin-top: 16px;
  margin-bottom: 0;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-bottom: 10px;
}

.title {
  // Splitting these to fix g character getting cut off
  padding-bottom: 2px;
  // margin-bottom: 9px !important;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;

  @media (max-width: $medium) {
    padding-bottom: 0;
    // margin-bottom: 16px !important;
  }
}

.largeTitle {
  font-size: 28px !important;
  font-weight: 500;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.featuredCardHalfColumn {
  padding: 48px;

  @media (max-width: $medium) {
    padding: 32px;
  }
}

.featuredCard {
  padding: 32px;
}

.halfColumnTitle {
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-bottom: 10px;
  margin-bottom: 0 !important;

  @media (max-width: $large) {
    padding-bottom: 0px;
  }

  @media (max-width: $medium) {
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-size: 28px !important;
    font-weight: 500;
  }
}

.emptyImage {
  height: 50%;
  width: 100%;
  background-color: $grey;
  opacity: 0.7;
}

.fullHeightCard {
  height: 100%;
}
